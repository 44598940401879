'use client';

import { memo } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, ListItem, SvgIcon, Badge, Link } from '@mui/material';
import { SidebarLink } from '@/src/components/Layouts/AppLayout/Sidebar/components/sidebarLinks';
type Props = Omit<SidebarLink, 'IconActive'> & {
  isActive: boolean;
  onClick?: () => void;
};
const SidebarNavItemInternal = ({
  title,
  href,
  isActive,
  isBadgeVisible = false,
  badgeDotColor = 'secondary.main',
  Icon,
  onClick
}: Props) => {
  return <ListItem disablePadding sx={{
    '& .MuiListItemIcon-root': {
      minWidth: 24,
      mr: 2
    }
  }} data-sentry-element="ListItem" data-sentry-component="SidebarNavItemInternal" data-sentry-source-file="SidebarNavItem.tsx">
      <ListItemButton component={Link} href={href} onClick={onClick} sx={{
      pl: 2.75,
      mb: 2
    }} data-sentry-element="ListItemButton" data-sentry-source-file="SidebarNavItem.tsx">
        <ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="SidebarNavItem.tsx">
          <Badge invisible={!isBadgeVisible} color="secondary" sx={{
          '.MuiBadge-badge': {
            bgcolor: badgeDotColor
          }
        }} variant="dot" data-sentry-element="Badge" data-sentry-source-file="SidebarNavItem.tsx">
            <SvgIcon sx={{
            color: isActive ? 'primary.main' : 'text.secondary'
          }} component={Icon} data-sentry-element="SvgIcon" data-sentry-source-file="SidebarNavItem.tsx" />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={title} slotProps={{
        primary: {
          variant: 'bodyLarge',
          color: 'text.primary',
          fontWeight: 500,
          noWrap: true
        }
      }} data-sentry-element="ListItemText" data-sentry-source-file="SidebarNavItem.tsx" />
      </ListItemButton>
    </ListItem>;
};
export const SidebarNavItem = memo(SidebarNavItemInternal);
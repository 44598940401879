'use client';

import { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { recordEvent, setGA4CurrentUser, setGA4SalesPilotAppID, setPreviousRoute } from '@qb/frontend/analytics/ga4';
import { webQueries } from '@qb/httpRequest/webQueries';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { LOGOUT_EVENT_NAME } from '@/src/components/Auth/hooks/useLogoutRequest';
import { setUser, fetchFeatureFlips } from '@/src/store/reducers/main';
import { useReduxDispatch } from '@/src/store/store';
type AppTrackingAndAnalyticsProps = {
  salesPilotAppID: number | undefined;
};
export const AppTrackingAndAnalytics = ({
  salesPilotAppID
}: AppTrackingAndAnalyticsProps) => {
  const dispatch = useReduxDispatch();
  const router = useRouter();
  setGA4SalesPilotAppID(salesPilotAppID);
  const {
    isSuccess,
    data
  } = useGetCurrentUserInfoRequest();
  const logRouteToAnalytics = useCallback((url: string) => {
    webQueries.analytics.logPageLoad.mutationFn({
      url: url
    });
    recordEvent('view_page', {
      page: {
        info: {
          url: url
        }
      }
    });
  }, []);
  useEffect(() => {
    dispatch(fetchFeatureFlips());
  }, [dispatch]);
  useEffect(() => {
    const handleLogout = (event: StorageEvent) => {
      if (event.key === LOGOUT_EVENT_NAME) {
        dispatch(setUser(null));
        Sentry.setUser(null);
        setGA4CurrentUser(undefined);
        router.push('/login');
      }
    };
    window.addEventListener('storage', handleLogout);
    return () => {
      window.removeEventListener('storage', handleLogout);
    };
  }, [dispatch, router]);
  useEffect(() => {
    type RouteChangeHandler = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => void;
    const handler: RouteChangeHandler = (url, {
      shallow
    }) => {
      if (shallow) {
        return;
      }
      setPreviousRoute(router.asPath);
      logRouteToAnalytics(url);
    };
    router.events.on('routeChangeStart', handler);
    return () => {
      router.events.off('routeChangeStart', handler);
    };
  }, [logRouteToAnalytics, router.asPath, router.events]);
  useEffect(() => {
    if (isSuccess) {
      setGA4CurrentUser(data.user);
      Sentry.setUser(data.user ? {
        id: String(data.user.id)
      } : null);
      logRouteToAnalytics(window.location.pathname);
    }
  }, [data?.user, isSuccess, logRouteToAnalytics]);
  return null;
};
'use client';

import { ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Drawer, Grid, Stack, SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
type RightDrawerProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  drawerPaperSx?: SystemStyleObject;
  gridContainerSx?: SxProps;
};
export const RightDrawer = ({
  children,
  isOpen,
  onClose,
  onBack,
  drawerPaperSx = {},
  gridContainerSx = {}
}: RightDrawerProps) => {
  const backIcon = <IconButton aria-label="back" sx={{
    position: 'absolute',
    top: 16,
    left: 16
  }} onClick={onBack}>
      <ArrowBackIcon />
    </IconButton>;
  const closeIcon = <IconButton aria-label="close" onClick={onClose} sx={{
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 2
  }}>
      <Close />
    </IconButton>;
  return <Drawer transitionDuration={0} anchor="right" open={isOpen} onClose={onClose} sx={{
    '& .MuiDrawer-paper': {
      width: [400, 600, 800, 1000],
      ...drawerPaperSx
    }
  }} slotProps={{
    paper: {
      elevation: 0
    }
  }} data-sentry-element="Drawer" data-sentry-component="RightDrawer" data-sentry-source-file="RightDrawer.tsx">
      <Stack sx={{
      width: '100%',
      height: '100%'
    }} data-sentry-element="Stack" data-sentry-source-file="RightDrawer.tsx">
        {onBack && backIcon}
        {closeIcon}
        <Grid container direction="row" sx={{
        overflowY: 'auto',
        maxHeight: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        py: [2, 3],
        px: [2, 3],
        ...gridContainerSx
      }} data-sentry-element="Grid" data-sentry-source-file="RightDrawer.tsx">
          {children}
        </Grid>
      </Stack>
    </Drawer>;
};